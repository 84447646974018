import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const siteId = "S00001";

// Thunk to fetch all specialists
export const fetchSpecialists = createAsyncThunk(
  "specialists/fetchSpecialists",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/specialists?site_id=${siteId}`, {
        headers: {
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Thunk to fetch a single specialist by ID
export const fetchSpecialistById = createAsyncThunk(
  "specialists/fetchSpecialistById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/specialists/${id}`, {
        headers: {
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Slice
const specialistsSlice = createSlice({
  name: "specialists",
  initialState: {
    all: {
      data: [], // Stores the list of all specialists
      loading: false, // Tracks loading state for all specialists
      error: null, // Tracks error for all specialists
    },
    byId: {
      data: null, // Stores data for a single specialist
      loading: false, // Tracks loading state for single specialist
      error: null, // Tracks error for single specialist
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    // All Specialists
    builder
      .addCase(fetchSpecialists.pending, (state) => {
        state.all.loading = true;
        state.all.error = null;
      })
      .addCase(fetchSpecialists.fulfilled, (state, action) => {
        state.all.loading = false;
        state.all.data = action.payload;
      })
      .addCase(fetchSpecialists.rejected, (state, action) => {
        state.all.loading = false;
        state.all.error = action.payload;
      });

    // Single Specialist by ID
    builder
      .addCase(fetchSpecialistById.pending, (state) => {
        state.byId.loading = true;
        state.byId.error = null;
      })
      .addCase(fetchSpecialistById.fulfilled, (state, action) => {
        state.byId.loading = false;
        state.byId.data = action.payload;
      })
      .addCase(fetchSpecialistById.rejected, (state, action) => {
        state.byId.loading = false;
        state.byId.error = action.payload;
      });
  },
});

export const specialistsReducer = specialistsSlice.reducer;

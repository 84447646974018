import { combineReducers } from "@reduxjs/toolkit";
import { specialistsReducer } from "./specialist";
import { menuExpandReducer } from "./menuExpand";
import { customersReducer } from "./customer";
import { servicesReducer } from "./services";
import { appointmentReducer } from "./appointment";

const rootReducer = combineReducers({
    specialists: specialistsReducer,
    menuExpand: menuExpandReducer,
    customers: customersReducer,
    services: servicesReducer,
    appointment: appointmentReducer,
});
export default rootReducer;
import { ThemeProvider } from '@mui/material'
import React from 'react'
import { Provider } from 'react-redux'
import { persistor, store } from './store'
import { theme } from './theme/Mui'
import ThemeContextProvider from './context/AppContext'
import MainRoutes from './routes'
import './App.css'
import { PersistGate } from 'redux-persist/integration/react'

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <ThemeContextProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <MainRoutes />
          </PersistGate>
        </Provider>
      </ThemeContextProvider>
    </ThemeProvider>
  )
}

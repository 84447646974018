import React, { useEffect, useRef, useState } from "react";
import Drawer from '@mui/material/Drawer';
import CreateAppointment from "./createAppointment";

export default function Index({
    addAppointmentToggle,
    setAddAppointmentToggle
}) {

    return (
        <>
            <Drawer
                anchor={"right"}
                open={addAppointmentToggle}
            >
                <CreateAppointment
                    addAppointmentToggle={addAppointmentToggle}
                    setAddAppointmentToggle={setAddAppointmentToggle} />
            </Drawer>

        </>
    )
}
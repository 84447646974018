import { Routes, Route, BrowserRouter } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Calendar from "../calendar/Calendar";
import ProtectedLayout from "../components/ProtectedLayout";

export default function MainRoutes() {
  return (
    <BrowserRouter>

    <Routes>
      <Route path="/" element={<ProtectedLayout />}>
        <Route
          index
          element={
            <ProtectedRoute>
              <Calendar />
            </ProtectedRoute>
          }
        />
      </Route>
      {/* Uncomment these as needed */}
      {/* <Route path="/login" element={<Login />} /> */}
      {/* <Route path="/forget-password" element={<ForgetPassword />} /> */}
    </Routes>
    </BrowserRouter>
  );
}

//import angelicHomeIcon from '../assets/images/MenuIcons/angelicHomeIcon.png';
// import angelicCalednarIcon from '../assets/images/MenuIcons/angelicCalednarIcon.png';
// import angelicCustomers from '../assets/images/MenuIcons/angelicCustomersIcon.png';
// import angelicPaymentIcon from '../assets/images/MenuIcons/angelicPaymentIcon.png';
// import angelicHistoryIcon from '../assets/images/MenuIcons/angelicHistoryIcon.png';
// import angelicAnalyticIcon from '../assets/images/MenuIcons/angelicAnalyticIcon.png';


const angelicHomeIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.99 20.0004H16V13.0004C16 12.4474 15.552 12.0004 15 12.0004H9C8.447 12.0004 8 12.4474 8 13.0004V20.0004H5L5.006 11.5834L11.998 4.43245L19 11.6244L18.99 20.0004ZM10 20.0004H14V14.0004H10V20.0004ZM20.424 10.1854L12.715 2.30145C12.338 1.91645 11.662 1.91645 11.285 2.30145L3.575 10.1864C3.21 10.5614 3 11.0854 3 11.6244V20.0004C3 21.1034 3.847 22.0004 4.888 22.0004H9H15H19.111C20.152 22.0004 21 21.1034 21 20.0004V11.6244C21 11.0854 20.79 10.5614 20.424 10.1854Z" fill="#898E99"/>
</svg>


const angelicCalednarIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7 16C7 15.45 7.45 15 8 15C8.55 15 9 15.45 9 16C9 16.55 8.55 17 8 17C7.45 17 7 16.55 7 16ZM12 15H16C16.55 15 17 15.45 17 16C17 16.55 16.55 17 16 17H12C11.45 17 11 16.55 11 16C11 15.45 11.45 15 12 15ZM18 20H6C5.449 20 5 19.551 5 19V13H19V19C19 19.551 18.551 20 18 20ZM6 6H7V7C7 7.55 7.45 8 8 8C8.55 8 9 7.55 9 7V6H15V7C15 7.55 15.45 8 16 8C16.55 8 17 7.55 17 7V6H18C18.551 6 19 6.449 19 7V11H5V7C5 6.449 5.449 6 6 6ZM18 4H17V3C17 2.45 16.55 2 16 2C15.45 2 15 2.45 15 3V4H9V3C9 2.45 8.55 2 8 2C7.45 2 7 2.45 7 3V4H6C4.346 4 3 5.346 3 7V19C3 20.654 4.346 22 6 22H18C19.654 22 21 20.654 21 19V7C21 5.346 19.654 4 18 4Z" fill="#898E99"/>
</svg>


const angelicCustomers = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 9.449 17.552 9 17 9C16.448 9 16 9.449 16 10C16 10.551 16.448 11 17 11C17.552 11 18 10.551 18 10ZM20 10C20 11.654 18.654 13 17 13C15.346 13 14 11.654 14 10C14 8.346 15.346 7 17 7C18.654 7 20 8.346 20 10ZM11 7C11 5.897 10.103 5 9 5C7.897 5 7 5.897 7 7C7 8.103 7.897 9 9 9C10.103 9 11 8.103 11 7ZM13 7C13 9.206 11.206 11 9 11C6.794 11 5 9.206 5 7C5 4.794 6.794 3 9 3C11.206 3 13 4.794 13 7ZM13.94 15.046C14.809 14.374 15.879 14 17 14C19.757 14 22 16.243 22 19C22 19.552 21.553 20 21 20C20.447 20 20 19.552 20 19C20 17.346 18.654 16 17 16C16.317 16 15.668 16.234 15.144 16.649C15.688 17.645 16 18.787 16 20C16 20.552 15.553 21 15 21C14.447 21 14 20.552 14 20C14 17.243 11.757 15 9 15C6.243 15 4 17.243 4 20C4 20.552 3.553 21 3 21C2.447 21 2 20.552 2 20C2 16.14 5.141 13 9 13C10.927 13 12.673 13.783 13.94 15.046Z" fill="#898E99"/>
</svg>

const angelicHistoryIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 16.402L13 17.75V7.59698L19 6.24998V16.402ZM5 6.24998L11 7.59698V17.75L5 16.402V6.24998ZM20.625 4.21798C20.387 4.02898 20.077 3.95698 19.78 4.02398L12 5.77198L4.22 4.02398C3.922 3.95598 3.613 4.02898 3.375 4.21798C3.138 4.40898 3 4.69598 3 4.99998V17.202C3 17.67 3.324 18.075 3.78 18.177L11.78 19.975C11.854 19.992 11.927 20 12 20C12.073 20 12.146 19.992 12.22 19.975L20.22 18.177C20.676 18.075 21 17.67 21 17.202V4.99998C21 4.69598 20.862 4.40898 20.625 4.21798Z" fill="#898E99"/>
</svg>


const angelicPaymentIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 7C2 5.34315 3.34315 4 5 4H15C16.6569 4 18 5.34315 18 7V8H19C20.6569 8 22 9.34315 22 11V17C22 18.6569 20.6569 20 19 20H9C7.34315 20 6 18.6569 6 17V16H5C3.34315 16 2 14.6569 2 13V7ZM8 15V17C8 17.5523 8.44772 18 9 18H19C19.5523 18 20 17.5523 20 17V11C20 10.4477 19.5523 10 19 10H17H9C8.44772 10 8 10.4477 8 11V15ZM16 8H9C7.34315 8 6 9.34315 6 11V14H5C4.44772 14 4 13.5523 4 13V7C4 6.44772 4.44772 6 5 6H15C15.5523 6 16 6.44772 16 7V8ZM14 13C13.4477 13 13 13.4477 13 14C13 14.5523 13.4477 15 14 15C14.5523 15 15 14.5523 15 14C15 13.4477 14.5523 13 14 13ZM11 14C11 12.3431 12.3431 11 14 11C15.6569 11 17 12.3431 17 14C17 15.6569 15.6569 17 14 17C12.3431 17 11 15.6569 11 14Z" fill="#898E99"/>
</svg>


const angelicAnalyticIcon =  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M19 4C18.45 4 18 4.45 18 5V20C18 20.55 18.45 21 19 21C19.55 21 20 20.55 20 20V5C20 4.45 19.55 4 19 4ZM12 8C11.45 8 11 8.45 11 9V20C11 20.55 11.45 21 12 21C12.55 21 13 20.55 13 20V9C13 8.45 12.55 8 12 8ZM4 13C4 12.45 4.45 12 5 12C5.55 12 6 12.45 6 13V20C6 20.55 5.55 21 5 21C4.45 21 4 20.55 4 20V13Z" fill="#898E99"/>
</svg>


export const menuItems = [

    {
        title: 'Home',
        icon: angelicHomeIcon,
        //key: 'profileAccordion',
        path: '/',
    },
    {
        title: 'Calendar',
        icon: angelicCalednarIcon,
        
        //key: 'profileAccordion',
        path: '/',
    },
    {
        title: 'Customers',
        icon: angelicCustomers,
        //key: 'profileAccordion',
        path: '/customers',
    },
    {
        title: 'History',
        icon: angelicHistoryIcon,
        //key: 'profileAccordion',
        path: '/history',
    },
    {
        title: 'Payment',
        icon: angelicPaymentIcon,
        //key: 'profileAccordion',
        path: '/payment',
    },
    {
        title: 'Analytics',
        icon: angelicAnalyticIcon,
        //key: 'profileAccordion',
        path: '/analytics',
    },
    // {
    //     title: 'Logout',
    //     icon: <LogoutIcon />,
    //     onclick: 'logout',
    // }
   
  ];
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const menuExpand = createSlice({
  name: "menuExpand",
  initialState: {
    all: {
      data: { menuStatus: false },
      loading: false,
      error: null,
    }
  },
  reducers: {
    toggleMenuStatus(state) {
      state.all.data.menuStatus = !state.all.data.menuStatus;
    },
    setMenuStatus(state, action) {
      state.all.data.menuStatus = action.payload;
    },
  }
});

export const { toggleMenuStatus, setMenuStatus } = menuExpand.actions;
export const menuExpandReducer = menuExpand.reducer;
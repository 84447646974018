import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const siteId = "S00001";

export const fetchServiceRecord = createAsyncThunk(
  "api/categories",
  async ({ searchVal }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/categories/${siteId}?search=${searchVal}`, {
        headers: {
          Accept: "application/json",
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const reduxSlice = createSlice({
  name: "services",
  initialState: {
    all: {
      data: [],
      loading: false,
      error: null,
    },
    byId: {
      data: null,
      loading: false,
      error: null,
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceRecord.pending, (state) => {
        state.all.loading = true;
        state.all.error = null;
      })
      .addCase(fetchServiceRecord.fulfilled, (state, action) => {
        state.all.loading = false;
        state.all.data = action.payload;
      })
      .addCase(fetchServiceRecord.rejected, (state, action) => {
        state.all.loading = false;
        state.all.error = action.payload;
      });
  },
});

export const servicesReducer = reduxSlice.reducer;
